<template>
  <div>
    <nav-header @clientchangedata="handlerdata"></nav-header>
    <div class="clients" >
      <div class="envCon" :class="lang">
        <div class="green-lease feature" v-if="this.$i18n.locale=='zh'">
          <div class="green-lease-title">
            <h4>绿色租赁</h4>
            <span>建立绿色租赁标准化流程，将绿色可持续发展目标</span>
            <span>与租赁全周期各环节深度关联，践行绿色租赁。</span>
          </div>
          <div class="green-lease-content-contianer">
            <h4>绿色租赁标准化流程</h4>
            <div class="green-lease-content">
              <div class="green-lease-content-left">
                <div class="num">1</div>
               <div class="text">
                 <span>绿色</span>
                 <span>交付</span>
               </div>
              </div>
              <div class="green-lease-content-right">
                <p>SOHO中国新开发办公项目全部采用美国绿色租赁LEED标准设计和建造，目已有8个LEED金级，1个LEED银级，旨在大厦交付源头，配备节能灯具、节水设备，设立垃圾分区，装修和厨余垃圾分类专区设立等绿色设施达到节能减排的目标。</p>
              </div>
            </div>
          </div>
          <div class="green-lease-content-contianer">
            <div class="green-lease-content">
              <div class="green-lease-content-left">
                <div class="num">2</div>
                <div class="text">
                  <span>入住</span>
                  <span>引导</span>
                </div>
              </div>
              <div class="green-lease-content-right">
                <p>在客户入住环节，将绿色租赁理念宣导、垃圾分类要求,日常绿色环保材料使用等具体绿色租赁措施要求形成详细宣传资料告知客户，引导客户理解绿色租赁理念与践行绿色租赁。</p>
              </div>
            </div>
          </div>
          <div class="green-lease-content-contianer">
            <div class="green-lease-content">
              <div class="green-lease-content-left">
                <div class="num">3</div>
                <div class="text">
                  <span>绿色</span>
                  <span>装修</span>
                </div>
              </div>
              <div class="green-lease-content-right">
                <p>为客户装修提供绿色装修指引与支持，帮助客户在装修期间使用节能环保材料与设施，为客户租赁期间降低能源消耗奠定良好基础，同时减少环境污染。</p>
              </div>
            </div>
          </div>
          <div class="green-lease-content-contianer">
            <div class="green-lease-content">
              <div class="green-lease-content-left">
                <div class="num">4</div>
                <div class="text">
                  <span>绿色</span>
                  <span>服务</span>
                </div>
              </div>
              <div class="green-lease-content-right">
                <p>组织绿色租赁活动，传播绿色租赁理念。为客户提供免费能源监测，提供能源优化建议，降低客户能源消耗。办公区域日常提供室内PM2.5空气质量监测，为客户提供良好办公环境。</p>
              </div>
            </div>
          </div>
          <div class="green-lease-content-contianer">
            <div class="green-lease-content">
              <div class="green-lease-content-left">
                <div class="num">5</div>
                <div class="text">
                  <span>绿色</span>
                  <span>推广</span>
                </div>
              </div>
              <div class="green-lease-content-right">
                <p>对客户进行绿色等级评测，对于达到相应等级客户给予“绿色租赁之星”称号，并给予宣传推广。</p>
              </div>
            </div>
          </div>
          <div class="green-lease-content-contianer">
            <div class="green-lease-content">
              <div class="green-lease-content-left">
                <div class="num">6</div>
                <div class="text">
                  <span>租金</span>
                  <span>激励</span>
                </div>
              </div>
              <div class="green-lease-content-right">
<!--                <p>为MSCI评级达到相应评级客户提供租金优惠激励；MSCI达到A，优惠1%，AA，优惠2%，AAA，优惠3%</p>-->
                <p>对于MSCI评级达到BBB等级及以上新签客户给予租金优惠激励。</p>
              </div>
            </div>
          </div>
        </div>
        <div class="green-lease feature" v-if="this.$i18n.locale=='en'">
          <div class="green-lease-title">
            <h4>GREEN LEASING</h4>
            <span>Establishing a standardized process for green leasing; deeply connecting the green sustainable development goals withlinks in the entire leasing cycle; implementing green leasing.</span>
            <span></span>

          </div>
          <div class="green-lease-content-contianer">
            <h4>Standardized Process for Green Leasing</h4>
            <div class="green-lease-content">
              <div class="green-lease-content-left">
                <div class="num">1</div>
                <div class="text">
<!--                  <span>绿色</span>-->
<!--                  <span>交付</span>-->
                  <span>Green Delivery</span>
                </div>
              </div>
              <div class="green-lease-content-right">
                <p>The newly developed office projects of SOHO China are all designed and constructed following the US Green Lease LEED standard. There are 8 projects with LEED Gold standard and 1 LEED Silver standard. Prior to delivery, the projects are equipped with green facilities such as energy-saving lamps and water-saving equipment, the establishment of decoration and waste partitions, and a designated area for food waste sorting to reahc the goal of energy saving and emission reduction.</p>
              </div>
            </div>
          </div>
          <div class="green-lease-content-contianer">
            <div class="green-lease-content">
              <div class="green-lease-content-left">
                <div class="num">2</div>
                <div class="text">
<!--                  <span>入住</span>-->
<!--                  <span>引导</span>-->
                  <span> Check-in Guidance</span>
                </div>
              </div>
              <div class="green-lease-content-right">
                <p>During the check-in process, detailed publicity materials are created to inform clients of specific green leasing measures such as the promotion of green leasing concept, waste classification requirements, and daily use of green environmental protection materials. They also help guide clients to understand the concept of green leasing and implement it.</p>
              </div>
            </div>
          </div>
          <div class="green-lease-content-contianer">
            <div class="green-lease-content">
              <div class="green-lease-content-left">
                <div class="num">3</div>
                <div class="text">
<!--                  <span>绿色</span>-->
<!--                  <span>装修</span>-->
                  <span>Green Decoration</span>
                </div>
              </div>
              <div class="green-lease-content-right">
                <p>Providing green decoration guidelines and support for clients' decoration; assisting them to use energy-saving and environmentally friendly materials and facilities during decoration, for the purpose of reducing energy consumption during the leasing period and mitigating environmental pollution.</p>
              </div>
            </div>
          </div>
          <div class="green-lease-content-contianer">
            <div class="green-lease-content">
              <div class="green-lease-content-left">
                <div class="num">4</div>
                <div class="text">
<!--                  <span>绿色</span>-->
<!--                  <span>服务</span>-->
                  <span> Green Service</span>
                </div>
              </div>
              <div class="green-lease-content-right">
                <p>Organizing green leasing activities to convey the concept of green leasing. Providing clients with free energy monitoring and energy optimization consultation to reduce energy consumption. The office area provides daily indoor PM2.5 air quality monitoring to ensure customers a good office environment.</p>
              </div>
            </div>
          </div>
          <div class="green-lease-content-contianer">
            <div class="green-lease-content">
              <div class="green-lease-content-left">
                <div class="num">5</div>
                <div class="text">
<!--                  <span>绿色</span>-->
<!--                  <span>推广</span>-->
                  <span>Green Promotion</span>
                </div>
              </div>
              <div class="green-lease-content-right">
                <p>Evaluate clients with green ratings, and awarding the title "Green Leasing Star" to the clients who qualify. Publicity and promotion will also be provided to them.</p>
              </div>
            </div>
          </div>
          <div class="green-lease-content-contianer">
            <div class="green-lease-content">
              <div class="green-lease-content-left">
                <div class="num">6</div>
                <div class="text">
<!--                  <span>租金</span>-->
<!--                  <span>激励</span>-->
                  <span> Rental Incentive</span>
                </div>
              </div>
              <div class="green-lease-content-right">
                <p>The Company will give preferential rent as incentives for new signing  customers whose MSCI rating has reached level BBB or above.</p>
              </div>
            </div>
          </div>
        </div>

      <!--        banner图-->
      <div class="greenpoint-container" v-if="this.$i18n.locale=='zh'">
          <div class="banner feature">
            <img src="../assets/greenpoint.png" alt="">
          </div>
          <div class="greenpoint-detail">
            <h3>绿色租赁指引</h3>
            <!-- <span>电子版 · 2023.3</span> -->
            <a href="http://sohochina.oss-cn-beijing.aliyuncs.com/ESG/20240514114426815.pdf" target="_blank"> <span>下载pdf</span></a>
          </div>
      </div>

        <div class="banner-container">
          <div class="banner feature">
            <img src="/img/client/banner.png" alt="">
          </div>
          <div class="banner-detail">
            <h3>{{content.maintitle}}</h3>
            <h4>{{content.title}}</h4>
          </div>
        </div>
      
        <div class="visit feature">
         {{content.date}}
        </div>
        <div class="visit-data feature">
          <div class="visit-people">
            <span class="num">{{content.people}}</span>
            <span class="text">{{$t('clients.visitunit')}}</span>
          </div>
          <div class="visit-company">
            <span class="num">{{content.conpanynum}}</span>
            <span class="text">{{$t('clients.visitunit1')}}</span>
  
          </div>
        </div>
        <div class="service feature">
          <div class="service-title" v-if="this.$i18n.locale=='zh'">
            <h4>持续提升物业服务水平</h4>
            <span >建立综合全面、快速响应的物业服务体系</span>
          </div>
          <div class="service-title" v-if="this.$i18n.locale=='en'">
            <h4>CONSISTENT IMPROVEMENT OF</h4>
            <h4 class="margin10">PROPERTY MANAGEMENT SERVICES</h4>
            <span>Establishing A Comprehensive And </span>
            <span class="margin5">Fast-Response Property Service System</span>

          </div>
          <div class="service-contennt">
            <ul>
              <li style="margin-left: 0;">
                <div class="img">
                  <img src="/img/client/file.png" alt="">
                </div>
                <div class="li-content">
                  <span>{{$t('clients.serviceLititle')}}</span>
                  <div class="li-content-flex ">
                    <div class="li-pub">
                      <div >
                        <span class="num">{{$t('clients.serviceLinum')}}</span>
                      </div>
                      <div class="li-pub-padding">
                        <span >{{$t('clients.servicetop')}}</span>
                        <span >{{$t('clients.servicebottom')}}</span>
                      </div>

                    </div>
                    <div class="li-pub">
                      <div>
                        <span class="num">1</span>
                      </div>
                      <div  class="li-pub-padding">
                        <span>{{$t('clients.servicetop1')}}</span>
                        <span>{{$t('clients.servicebottom1')}}</span>
                      </div>

                    </div>
                  </div>

                </div>
              </li>

              <li>
                <div class="img">
                  <img src="/img/client/rubot.png" alt="">
                </div>
                <div class="li-content li-content-pad">
                  <span>{{$t('clients.serviceLititle2')}}</span>
                  <div class="li-content-flex ">
                    <div>
                      <span class="num">{{$t('clients.serviceLinum2')}}</span>
                    </div>
                    <div style="margin-left: 5px">
                      <span>{{$t('clients.servicetop2')}}</span>
                      <span>{{$t('clients.servicebottom2')}}</span>
                    </div>
                  </div>

                </div>
              </li>
              <li >
                <div class="img">
                  <img src="/img/client/wifi.png" alt="">
                </div>
                <div class="li-content">
                  <p style="text-align: left">
                    {{$t('clients.servicecontent')}}
                  </p>

                </div>
              </li>
              <li class="last-li">
                <div class="img">
                  <img src="/img/client/search.png" alt="">
                </div>
                <div class="li-content">
                  <p style="text-align: left"> {{$t('clients.serviecontent1')}}</p>
                  <p>{{$t('clients.serviecontent2')}}</p>

                </div>
              </li>
            </ul>
          </div>

        </div>
        <!-- 体系 -->
        <div class="system feature">
          <div v-if="this.$i18n.locale=='en'">
            <h4>BUILDING A CUSTOMER INFORMATION</h4>
            <h4 class="margin10">SECURITY SYSTEM</h4>
          </div>

          <h4 v-if="this.$i18n.locale=='zh'"> 构建客户信息安全体系</h4>
          <div class="system-content">
            <div class="system-content-left">
              <p>{{$t('clients.systemcontent')}}</p>
            </div>
            <div class="system-content-right" v-if="this.$i18n.locale =='zh'">
              <img src="/img/client/systom.png" alt="">
            </div>

            <div class="system-content-right" v-if="this.$i18n.locale =='en'">
              <img src="/img/client/ensystom.png" alt="">
            </div>

          </div>

        </div>

        <div class="handler feature">
          <div v-if="this.$i18n.locale=='en'">
            <h4>CUSTOMER SATISFACTION SURVEY</h4>
            <h4 class="margin10">AND COMPLAINT HANDLING</h4>
          </div>

          <h4 v-if="this.$i18n.locale=='zh'"> 客户满意度调查及投诉处理</h4>
          <div class="handler-content">
            <div class="handler-content-left">
              <p>
                {{$t('clients.handlercontent')}}
              </p>
            </div>

            <div class="handler-content-right">
              <span class="num">99.81</span>
              <span v-if="this.$i18n.locale=='zh'">分</span>
              <span class="handler-content-right-text"> {{$t('clients.handlerdetail')}}</span>
              <img src="/img/client/star.png" alt="">
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
import NavHeader from "../components/Header"
import {mapState} from "vuex"
import {getdataInfo} from "../api/home";
export default {
  name: "Clients",
  components: {
    NavHeader
  },
  data() {
    return{
      id: 5,
      pageType: 'client',
      content:{}
    }
  },
  mounted() {
    const staggeringOption = {
      delay: 300,
      distance: "50px",
      duration: 500,
      easing: "ease-in-out",
      origin: "bottom",
    };

    this.$scroll().reveal(".feature", { ...staggeringOption, interval: 350 });
    this.getdata()
  },
  computed:{
    ...mapState({
      lang:state => state.lang
    })
  },
  methods:{
      async getdata() {
        const data = await getdataInfo({ id: this.id, pageType: this.pageType })
        const zh_data= JSON.parse(data.esgContent.cnContent)
        const en_data= JSON.parse(data.esgContent.enContent)
        console.log(zh_data)
        if(this.$i18n.locale=='zh'){
          this.content = zh_data
        }else {
          this.content = en_data
        }
      },
      handlerdata(){
        this.getdata()
      },
    },
}
</script>

<style scoped lang="scss">
.en .green-lease .green-lease-content-right{
  p{
    font-family: "Barlow-Regular";
    line-height: 30px;
  }

}
.en .green-lease .green-lease-title span {
  font-family: "Barlow-Regular";
  text-align: left;
  padding: 0 80px;
}
.en .green-lease .green-lease-content-left{
  width: 30%;
}
.en .green-lease .green-lease-content-left .num{
  width: 68%;
}
.en .green-lease .green-lease-content-left .num{
  width: 55px;
}

.clients{
  background-color: #F5F5F7;
  height: 100%;
  padding-top: 70px;

}
.green-lease{
  background: #ffffff;
  border-radius: 20px;
  padding-bottom: 80px;
  padding-top: 66px;
  text-align: center;
  .green-lease-title{
    padding-bottom: 100px;
    h4{
      padding-bottom: 10px;
    }
    span{
      margin-top: 5px;
      font-size: 24px;
      display: block;
      text-align: center;
      color: #353535;
      line-height: 34px;
      letter-spacing: 1px;
    }
  }
  .green-lease-content-contianer{
    h4{
      font-size: 28px;
      padding-bottom: 50px;
    }

  }
  .green-lease-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 88%;
    margin: 0 auto;
    padding-bottom: 60px;
  }
  .green-lease-content-left{
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100px;
    margin-right: 30px;
    .num{
      width: 49px;
      height: 66px;
      background-color: rgb(46, 196, 182);
      color: #ffffff;
      border-radius: 5px;
      font-size: 50px;
      font-family: "Barlow-Regular";
      margin-top: 3px;
    }
    .text{
      margin-left: 20px;
      flex: 1;
      text-align: left;
      span{
        font-size: 28px;
        color: rgb(46, 196, 182);
        font-weight: 800;
      }

    }
    span{
      display: block;
    }
  }
  .green-lease-content-right{
    width: 82%;
    p{
      letter-spacing: 1px;
      font-size: 18px;
      line-height:34px;
      color: #353535;
      padding-right: 26px;
    }

  }
}
.greenpoint-container{
  position: relative;
  margin-top: 50px;
}
.greenpoint-detail{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  h3{
    font-size: 44px;
    color: #116458;
    letter-spacing: 1px;
    font-weight: 600;
  }
  span{
    font-size: 12px;
    color: #67AAA0;
  }
  a{
    display: block;
    margin: 30px auto 0;
    width: 200px;
    height: 54px;
    background: #3EC8B3;
    text-align: center;
    line-height: 54px;
    color: #ffffff;
    border-radius: 10px;
    span{
      color: #ffffff;
      font-size: 20px;
      letter-spacing: 1px;
    }
  }
}
.banner-container {
  position: relative;
  margin-top: 50px;
}
h4 {
  font-size: 44px;
  color:#22c3ad;
  font-weight: bold;
  text-align: center;
}
.en h4{
  font-family: "BarlowCondensed-bold";
}
.banner-detail{
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 40px;
  color: #FFFFFF;
  text-align: center;

  h3{
    font-size: 62px;
    font-weight: 700;
  }
  h4{
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
  }

}
.margin10{
  margin-top: -10px;
}
.margin5{
  margin-top: -5px;
}

.en .banner-detail{

  h3{
    font-size: 62px;
    font-weight: 700;
    font-family: "BarlowCondensed-bold";

  }
  h4{
    font-size: 24px;
    font-weight: 500;
    font-family: "Barlow-Regular";
  }


}
p{
  text-align: justify;
}
.banner{
  width: 100%;
}
.banner img{
  width: 100%;
  display: block;
}

.visit{
  width: 100%;
  font-size: 18px;
  text-align: center;
  padding:50px 0;
}
.en .visit{
  font-size: 32px;
  font-family: "BarlowCondensed-Regular";
}

.visit-data {
  display: flex;
  justify-content: space-between;
  color: #353535;
  font-family:Barlow Condensed;
  padding-bottom: 90px;
}
.visit-people{
  width: 45%;
  text-align: right;
}
.visit-company{
  width: 45%;
}

.visit-data .num{

  font-size: 72px;
  font-family: "BarlowCondensed-bold";
  font-weight: bold;
}
.visit-data .text{
  font-size: 36px;
  margin-left: 10px;

}
.en .visit-data .text{
  font-family: "BarlowCondensed-Regular";
  font-size: 24px;

}

/* 服务 */
.service{
  padding: 66px 35px;
  background-color: #ffffff;
  border-radius: 30px;
  margin-bottom: 30px;
}

.service-title span{
  margin-top: 5px;
  font-size: 24px;
  display: block;
  text-align: center;
  color: #353535;
  line-height: 34px;
  letter-spacing: 1px;
}

.service-contennt{
  padding-top: 130px;
}
.service-contennt>ul{
  display: flex;
}
.service-contennt li{
  width: 22%;
  box-sizing: border-box;
  position: relative;
  margin-left: 4%;

}
.service-contennt li:after{
  content: "";
  position: absolute;
  top: 90px;
  right: -20px;
  width: 2px;
  height: 130px;
  background-color:#CCCBCD;
}
.service-contennt .last-li::after{
  content: "";
  position: absolute;
  top: 60px;
  right: -20px;
  width: 0;
  height:0px;

}
.service-contennt img{
  width: 72px;
  height: 72px;
}

.li-content{
  padding-top: 30px;


}
.li-content-pad{
  padding-left: 20px;
}
.li-content span{
  display: block;
  font-size: 18px;
  color: #353535;
}
.en .li-content span{
  font-size: 16px;
  font-family: "BarlowCondensed-Regular";
}
.li-content .num{
  font-size: 62px;
  font-family: "BarlowCondensed-bold";
  font-weight: bold;

}
.en .li-content .num{
  font-size: 62px;
  font-family: "BarlowCondensed-bold";
}

.li-content .li-content-flex{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  align-items: center;
}

.li-pub{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.li-pub-padding{
  padding: 10px;
}
.en .li-pub-padding{
  padding:6px;
}

.li-content p{
  font-size: 14px;
  line-height: 2;
}
.en .li-content p{
  font-size: 16px;
  line-height:20px;
  font-family: "BarlowCondensed-Regular";
}

.system{
  padding:70px 65px;
  background-color: #ffffff;
  border-radius: 30px;
}


.system .system-content{
  margin-top: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.system-content-left p{
  font-size: 18px;
  line-height: 32px;
  color: rgb(53, 53, 53);
  padding-right: 25px;
}

.en .system-content-left p{
  font-family: "Barlow-Regular";
  line-height: 26px;
}
.system-content-right{
  margin-left: 20px;
}
.en .system-content-right img {
  display: block;
  width: 641px;
  height: 294px;
}
/* 处理 */
.handler{
  margin-top: 170px;
  padding-bottom: 170px;
}

.handler-content{
  display: flex;
  flex-direction: row;

  align-items: center;
  margin-top: 100px;
}
.handler-content-left{
  width: 46%;
  padding-left: 10%;
}

.handler-content-left p{
  font-size: 18px;
  line-height: 32px;
  color: rgb(53, 53, 53);
  padding-right: 25px;

}
.en .handler-content-left p{
  font-family: "Barlow-Regular";
}

.handler-content-right{
  width: 44%;
  text-align: center;

}
.handler-content-right .num{
  font-size: 72px;
  font-family: "BarlowCondensed-bold";
  font-weight: bold;
  color: #353535;
}
.handler-content-right-text{
  display: block;
  font-size: 18px;
  color: #353535;
}
.en .handler-content-right-text{
  font-size: 20px;
  font-family: "BarlowCondensed-Regular";
}
.handler-content-right img{
  margin-top: 10px;
  width: 237px;
  height: 44px;
}
@media (max-width: 680px) {
  .en .green-lease .green-lease-content-left{
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .en .green-lease .green-lease-title span {
    padding: 0 10px;
  }
  .en .green-lease .green-lease-content-left .num{
    width: 40px;
  }
  .en .green-lease .green-lease-content-right p {
   text-align: left;
  }
  .green-lease{
    border-radius: 20px;
    padding-bottom: 40px;
    padding-top: 66px;
    text-align: center;
    .green-lease-title{
      padding-bottom: 60px;
      h4{
        padding-bottom: 10px;
      }
      span{
        margin-top: 5px;
        font-size: 16px;
        display: block;
        text-align: left;
        color: #353535;
        line-height: 28px;
        letter-spacing: 1px;
        padding: 0 5px;
      }
    }
    .green-lease-content-contianer{
      h4{
        font-size: 20px;
        padding-bottom: 20px;
      }

    }
    .green-lease-content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 90%;
      margin: 0 auto;
      padding-bottom: 10px;
    }
    .green-lease-content-left{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      margin-right: 5px;
      .num{
        width: 28px;
        height: 46px;
        background-color: rgb(46, 196, 182);
        color: #ffffff;
        border-radius: 5px;
        font-size: 30px;
        font-family: "Barlow-Regular";
        margin-top: 1px;
      }
      .text{
        margin-left: 5px;
        span{
          font-size: 18px;
          color: rgb(46, 196, 182);
          font-weight: 800;
        }

      }
      span{
        display: block;
      }
    }
    .green-lease-content-right{
      width: 100%;
      p{
        letter-spacing: 1px;
        font-size: 16px;
        line-height:28px;
        color: #353535;
        text-align: justify;
        padding-right: 0px;
      }
    }
  }
  .banner-container {
    position: relative;
  }
  h4 {
    font-size: 28px;
    color:#22c3ad;
    font-weight: bold;
    text-align: center;
  }
  .en h4{
    font-size: 26px;
  }
  .margin10{
    margin-top:0px;
  }
  .margin5{
    margin-top:0px;
  }
  .en .li-pub-padding{
    padding:0px;
  }
  .en .li-content span{
    font-size: 14px;
  }
  .banner-detail{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 10px;
    color: #FFFFFF;
    text-align: center;
    h3{
      font-size: 26px;
      font-weight: 700;
    }
    h4{
      font-size: 14px;
      font-weight: 300;
    }

  }
  .en .banner-detail{

    h3{
      font-size: 26px;
      font-weight: 700;
    }
    h4{
      font-size: 18px;
      font-weight: 300;
    }

  }

  .clients{
    background-color: #F5F5F7;
    height: 100%;
    padding-top: 30px;

  }
  .banner{
    width: 100%;
  }
  .banner img{
    width: 100%;
    display: block;
  }

  .visit{
    width: 100%;
    font-size: 18px;
    text-align: center;
    padding:10px 0;
  }
  .en .visit[data-v-66451d14] {
    font-size: 22px;
    font-family: "BarlowCondensed-Regular";
  }

  .visit-data {
    display: flex;
    justify-content: space-between;
    color: #353535;
    font-family:Barlow Condensed;
    padding-bottom: 45px;
  }
  .visit-people{
    width: 45%;
    text-align: right;
  }
  .visit-company{
    width: 45%;
  }

  .visit-data .num{

    font-size: 42px;
    font-family: "BarlowCondensed-bold";
    font-weight: bold;
  }
  .visit-data .text{
    font-size: 24px;
    margin-left: 10px;

  }

  /* 服务 */
  .service{
    padding: 66px 15px;
    background-color: #ffffff;
    border-radius: 10px;
    margin-bottom: 30px;
  }

  .service-title span{
    margin-top: 5px;
    font-size: 16px;
    display: block;
    text-align: center;
    color: #353535;
    line-height: 34px;
    letter-spacing: 1px;
  }
  .en .service-title span{
    font-size: 14px;
    line-height: 24px;
  }

  .service-contennt{
    padding-top: 35px;
  }
  .service-contennt>ul{
    display: flex;
    flex-direction: column;
  }
  .service-contennt li{
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-left:0;
    display: flex;
    align-items:center;
    justify-content: flex-start;
    margin-top: 25px;
    padding: 0 20px;

  }
  .service-contennt li:after{

    width: 0px;
    height: 0px;

  }
  .service-contennt .last-li::after{
    content: "";
    position: absolute;
    top: 60px;
    right: -20px;
    width: 0;
    height:0px;

  }
  .service-contennt img{
    width: 50px;
    height: 50px;
  }

  .li-content{
    padding-top:0px;
    padding-left: 50px;
  }
  .li-content-pad{
    padding-left: 50px;
  }
  .li-content span{
    display: block;
    font-size: 14px;
    color: #353535;
  }

  .li-content .num{
    font-size: 46px;
    font-family: "BarlowCondensed-bold";
    font-weight: bold;

  }

  .li-content .li-content-flex{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 5px;
    align-items: center;

  }

  .li-pub{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .li-pub-padding{
    padding: 10px;
  }

  .li-content p{
    font-size: 14px;
    line-height: 2;

  }
  .en .li-content p{
    line-height: 1.5;
    font-size: 14px;
  }

  .system{
    padding:66px 0px;
    background-color: #ffffff;
    border-radius: 10px;
  }
  .system-content-right{
    margin-left:0px;
  }
  .system .system-content{
    margin-top: 50px;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-between;

  }
  .system-content-left p{
    font-size: 14px;
    line-height: 28px;
    color: rgb(53, 53, 53);
    padding-right: 0px;
    padding: 0 10px;
  }
  .en .system-content-left p{
    line-height: 1.5;
    text-align: left;
  }

  .system-content-right img{
    display: block;
    width: 100%;
    margin-top: 15px;
    margin-left: 0px;
    height: auto;
  }
  .en .system-content-right img{
    display: block;
    width: 100%;
    height: auto;
  }
  /* 处理 */
  .handler{
    margin-top: 55px;
    padding-bottom: 70px;
  }

  .handler-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 55px;
  }



  .handler-content-left{
    width:100%;
    padding-left:0%;
  }
  .handler-content-left p{
    font-size: 14px;
    line-height: 28px;
    color: rgb(53, 53, 53);
    padding-right: 0px;
    padding: 0 10px;

  }
 .en .handler-content-left p{
   line-height: 1.5;
   text-align:left;
 }
  .handler-content-right{
    width:100%;
    text-align: center;
    margin-top: 15px;
  }
  .handler-content-right .num{
    font-size: 42px;
    font-family: "BarlowCondensed-bold";
    font-weight: bold;
    color: #353535;
  }
  .handler-content-right-text{
    display: block;
    font-size: 16px;
    color: #353535;
  }
  .handler-content-right img{
    margin-top: 10px;
    width: 180px;
    height: 34px;
  }

.greenpoint-detail{
  h3{
    font-size: 24px;
    color: #116458;
    letter-spacing: 1px;
    font-weight: 600;
  }
  span{
    font-size: 12px;
    color: #67AAA0;
  }
  a{
    display: block;
    margin: 20px auto 0;
    width: 150px;
    height: 34px;
    background: #3EC8B3;
    text-align: center;
    line-height: 34px;
    color: #ffffff;
    border-radius: 10px;
    span{
      color: #ffffff;
      font-size: 16px;
      letter-spacing: 1px;
    }
  }
}
}
</style>
